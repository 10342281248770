import React, { useCallback, useRef } from 'react'
import { TabsContainer } from '../tabsContainer'
import '../../styles/projects.css'
import img1 from './about/01.jpg'
import img2 from './about/02.jpg'
import img3 from './about/03.jpg'
import img4 from './about/04.jpg'
import img5 from './about/05.jpg'
import img6 from './about/06.jpg'
import pdf from './about/vss.pdf'
import pdfJb from './about/jb.pdf'
import { isMobile } from 'react-device-detect'
import { BsArrowRight } from 'react-icons/bs'

export function AboutComponent() {
    // script.ts
    window.addEventListener('DOMContentLoaded', () => {
        const scrollContainer = document.querySelector(
            '.scroll-container'
        ) as HTMLDivElement

        scrollContainer.addEventListener('wheel', (event) => {
            event.preventDefault()
            scrollContainer.scrollLeft += event.deltaY
        })
    })
    const scrollContainerRef = useRef<HTMLDivElement | null>(null)

    const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
        if (scrollContainerRef.current) {
            event.preventDefault()
            scrollContainerRef.current.scrollLeft += event.deltaY
        }
    }
    const widthByDevice = useCallback(() => {
        if (isMobile) {
            return 350
        }
        return 450
    }, [])

    return (
        <TabsContainer>
            <hr />
            <div className="projectContainer">
                <div className="wakeup">ÜBER UNS</div>
            </div>
            <hr />
            <div
                className="scroll-container"
                ref={scrollContainerRef}
                onWheel={handleScroll}
            >
                <div
                    className="scroll-content"
                    style={{ height: isMobile ? '60vh' : '65vh' }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '2rem',
                            justifyContent: 'center',
                            marginLeft: '2rem',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                gap: '2rem',
                                fontWeight: 'bold',
                            }}
                        >
                            <span className="scroll">SCROLL</span>{' '}
                            <BsArrowRight />
                        </div>
                    </div>
                    <div className="txtimg">
                        <img
                            src={img1}
                            alt="alt"
                            height={widthByDevice()}
                            style={{ marginLeft: '2rem' }}
                        />
                        <div className="projectText">
                            Der Verein socialarchitecture wurde 2022 in Münster
                            gegründet, initiiert von Jan Glasmeier und Oliver
                            Giebels. Während Jan sich schon seit einigen Jahren
                            privat engagiert und diverse Schulen und
                            Gesundheitsbauten vor allem in Mae Sot (Thailand)
                            realisiert hat, stieß Oliver Im Jahre 2021 dazu und
                            realisierte gemeinsam mit Jan den Neubau einer
                            Schule in Nakivale, einer UNHCR Flüchtlingssiedlung
                            im Süden Ugandas. Da sich die private Finanzierung
                            solcher Projekte auf Dauer schwierig gestaltet, fiel
                            die Entscheidung einen Verein zu gründen.
                        </div>
                        <div className="projectText">
                            Unsere selbsternannte Aufgabe ist die Initiierung,
                            Organisation und Unterstützung von Bau- und
                            Planungsprojekten zur Bereitstellung von Wohn-.
                            Schul- und Versorgungsgebäuden in
                            Entwicklungsländern. Dabei orientieren wir uns an
                            den <br />
                            <a
                                style={{ textDecoration: 'none' }}
                                href="https://www.bundesregierung.de/breg-de/themen/nachhaltigkeitspolitik/nachhaltigkeitsziele-erklaert-232174"
                            >
                                Zielen der Agenda
                            </a>{' '}
                            2023 der Vereinten Nationen. Zentrale Aspekte
                            hierbei sind die Reduzierung von Disparitäten im
                            Lebensstandard, die Schaffung von Chancengleichheit
                            sowie ein nachhaltiges Management von natürlichen
                            Ressourcen.
                        </div>
                    </div>
                    <div className="txtimg">
                        <figure style={{ margin: 0 }}>
                            <img
                                src={img2}
                                alt="alt"
                                height={isMobile ? 350 : 440}
                                style={{ marginLeft: '2rem' }}
                            />
                            <figcaption>
                                <a
                                    style={{
                                        textDecoration: 'none',
                                        marginLeft: '2rem',
                                    }}
                                    href="https://www.bundesregierung.de/resource/image/1552094/16x9/990/557/256038aeeb2d4597d3f6d0154133a91d/Qs/2018-11-20-17-ziele-fuer-nachhaltige-entwicklung.png"
                                >
                                    Quelle
                                </a>
                            </figcaption>
                        </figure>
                        <div className="projectText">
                            Bei unseren Architekturprojekten legen wir großen
                            Wert auf den Einsatz von kreislauffähigen und
                            ökologischen Materialien und verzichten wo es nur
                            geht auf den Einsatz von CO2 intensiven Baustoffen
                            wie Beton. <br />
                            Ein weiterer wichtiger Punkt ist das Einbeziehen der
                            lokalen Bevölkerung, in Theorie und Praxis. Wir
                            ermitteln gemeinsam den individuellen Bedarf der
                            Nutzer an die Gebäude, tauschen Wissen über
                            Baumaterialen und Bauweisen aus und schaffen vor,
                            während und nach der Bauzeit Arbeitsplätze für die
                            Menschen vor Ort. Darüber hinaus kaufen wir
                            Rohstoffe und Baumaterialien wo es nur geht bei
                            kleinen, ortsansässigen Händlern.
                        </div>
                        <div className="projectText">
                            Der Verein arbeitet rein ehrenamtlich und steht
                            grundsätzlich allen Menschen offen.
                            <br />
                            <br />
                            Bei Interesse finder ihr hier die {' '}
                            <a
                                style={{
                                    color: 'black',
                                    fontWeight: 'bold'
                                }}
                                href={pdf}
                            >
                                Satzung
                            </a> und hier unseren <br />
                            <a
                                style={{
                                    color: 'black',
                                    fontWeight: 'bold'
                                }}
                                href={pdfJb}
                            >
                                Jahresbericht 2024
                            </a>
                            
                        </div>
                    </div>
                    <div className="txtimg">
                        <img
                            src={img3}
                            alt="alt"
                            height={widthByDevice()}
                            style={{ marginLeft: '2rem' }}
                        />
                        <img
                            src={img4}
                            alt="alt"
                            height={widthByDevice()}
                            style={{ marginLeft: '2rem' }}
                        ></img>
                        <img
                            src={img5}
                            alt="alt"
                            height={widthByDevice()}
                            style={{ marginLeft: '2rem' }}
                        ></img>
                        <img
                            src={img6}
                            alt="alt"
                            height={widthByDevice()}
                            style={{ marginLeft: '2rem' }}
                        ></img>
                    </div>
                    <div style={{ color: 'white' }}>hidden text</div>
                </div>
            </div>
            <hr />
        </TabsContainer>
    )
}
